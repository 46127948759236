import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 2rem;
  @media (max-width: 500px) {
    margin: 0 1rem;
  }

  max-width: 1440px;
  height: ${({ full }) => (full ? '80%' : '')};
`;
