import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import GlobalStyle, { theme } from 'global.css.js';
import BackgroundImage from 'gatsby-background-image';
import styled, { ThemeProvider } from 'styled-components';

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: cover;
  max-width: 100vw;
  max-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
`;

const Layout = ({ data, children, ...props }) => (
  <StyledBackgroundImage fluid={data.bgi.childImageSharp.fluid}>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ContentWrapper {...props}>{children}</ContentWrapper>
    </ThemeProvider>
  </StyledBackgroundImage>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        bgi: file(relativePath: { eq: "bg.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
